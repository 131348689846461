<template>
  <v-container id="tienda">
    <v-row>
      <pricing-card
        v-for="product in products"
        :key="product.id"
        :value="product"
      />
    </v-row>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'OnlineStore',
    components: {
      PricingCard: () => import('@/components/base/PricingCard'),
    },
    computed: {
      ...mapState(['products']),
    },
  }
</script>

<style scoped>

</style>
